<template>
  <!--  <page-header-wrapper>-->
  <div style="width: 100%;height:100vh;">
    <div style="margin-bottom: 20px">
      <a-button @click="getTimeSelect" style="background-color: rgb(234,233,232);border-radius: 10%;margin-left: 2%">
        日期选择
      </a-button>
      <a-date-picker v-if="this.timeflag" v-model="payTime"
                     style="background-color: rgb(234,233,232);border-radius: 10%;margin-left: 0.5%"
                     format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      <a-button @click="selectTopData(1)" style="background-color: rgb(234,233,232);border-radius: 10%;margin-left: 0.5%">今日</a-button>
      <a-button @click="selectTopData(2)" style="background-color: rgb(234,233,232);border-radius: 10%;margin-left: 0.5%">昨天</a-button>
      <a-button @click="selectTopData(3)" style="background-color: rgb(234,233,232);border-radius: 10%;margin-left: 0.5%">近7天</a-button>
      <a-button @click="selectTopData(4)" style="background-color: rgb(234,233,232);border-radius: 10%;margin-left: 0.5%">近30天</a-button>

      <div style="background-color: rgb(240,242,245);padding-left: 1%;display: flex;width: 100%;margin-top: 0.5%">


        <a-card :bordered="false"
                style="background-color:white;width: 24%;height: 100%;border-radius: 5%;margin:0.5%;padding-top: 1%">
          <p><font style="font-size: 20px;">用户总数</font></p>
          <p><font style="font-size: 20px;font-weight: bold;margin-top: 1%">{{this.topData.totalUser}}</font></p>
        </a-card>

        <a-card :bordered="false"
                style="background-color:white;width: 24%;border-radius: 5%;margin:0.5%;padding-top: 1%">
          <p><font style="font-size: 20px">消费人数</font></p>
          <p><font style="font-size: 20px;font-weight: bold">{{this.topData.totalPay}}</font></p>
        </a-card>

        <a-card :bordered="false"
                style="background-color:white;width: 24%;border-radius: 5%;margin:0.5%;padding-top: 1%">
          <p><font style="font-size: 20px">未消费人数</font></p>
          <p><font style="font-size: 20px;font-weight: bold">{{this.topData.noTotalPay}}</font></p>
        </a-card>

        <a-card :bordered="false"
                style="background-color:white;width: 24%;border-radius: 5%;margin:0.5%;padding-top: 1%">
          <p><font style="font-size: 20px">订阅总数</font></p>
          <p><font style="font-size: 20px;font-weight: bold">{{this.topData.vipTotal}}</font></p>
        </a-card>


      </div>
    </div>

    <div style="display:flex;height: 30%;width: 100%;margin-top: 2%">

      <a-row style="width: 32%;height: 100%;background-color: white;border-radius: 5%;margin-left: 1%;margin-left: 1%">
        <div style="display:flex;width: 100%;height: 100%">
          <div id="myChart" style="width: 100%;height: 100%"></div>
        </div>
      </a-row>

      <a-row style="width: 32%;height: 100%;background-color: white;border-radius: 5%;margin-left: 2%">
        <div style="display:flex;width: 100%;height: 100%">
          <div id="myChart1" style="width: 100%;height: 100%"></div>
        </div>
      </a-row>

      <a-row style="display:flex;width: 32%;height: 100%;background-color: white;border-radius: 5%;margin-left: 2%">
        <!--<div style="display:flex;width: 10%;margin-left: 1%;margin-top: 10%;float:left" v-for="item in this.payTypeTotal">
          {{item.name}}:{{item.value}}
        </div>-->
        <div style="margin-left: 1%;margin-top: 8%;">
          <div v-for="item in this.payTypeTotal">
            {{item.name}}：{{item.value}}
          </div>
        </div>
        <div style="display:flex;width: 80%;height: 100%">
          <div id="myChart2" style="width: 100%;height: 100%">
          </div>
        </div>


      </a-row>
    </div>


    <div style="display:flex;height: 30%;width: 100%;margin-top: 2%">
      <a-row style="display:flex;width: 32%;height: 100%;background-color: white;border-radius: 5%;margin-left: 1%">
        <div style="margin-left: 1%;margin-top: 8%;">
          <div v-for="item in this.payTypeTotalMoney">
            {{item.name}}：{{item.value}}
          </div>
        </div>

        <div style="display:flex;width: 60%;height: 100%">
          <div id="myChart3" style="width: 100%;height: 100%"></div>
        </div>
      </a-row>

      <a-row style="display:flex;width: 32%;height: 100%;background-color: white;border-radius: 5%;margin-left: 2%">
        <div style="margin-left: 1%;margin-top: 8%; overflow-y: scroll;">
          <div v-for="item in this.rechargeAddress">
            {{item.name}}：{{item.value}}
          </div>
        </div>

        <div style="display:flex;width: 60%;height: 100%">
          <div id="myChart4" style="width: 100%;height: 100%"></div>
        </div>
      </a-row>

      <a-row style="display:flex;width: 32%;height: 100%;background-color: white;border-radius: 5%;margin-left: 2%">
        <div style="margin-left: 1%;margin-top: 8%;overflow-y: scroll;">
          <div v-for="item in this.vipAddress">
            {{item.name}}：{{item.value}}
          </div>
        </div>

        <div style="display:flex;width: 60%;height: 100%">
          <div id="myChart5" style="width: 100%;height: 100%"></div>
        </div>
      </a-row>

    </div>


  </div>


</template>

<script>

import {
  vipData,
  getTopData,
  gerVipAddressData,
  gerPayAddressData,
  gerPayMoneyData,
  payTypeData,
  rechargeData
} from "@/api/system/data";
import * as echarts from 'echarts';
import {getUserTotalData} from "@/api/biz/userForm";
import {getRechargePal} from "@/api/system/user";

export default {
  name: 'Index',
  components: {},
  data() {
    return {
      timeflag: false,
      payTime: null,
      //ios充值趋势
      iosRecharge: [],
      //paypal充值趋势
      paypalRecharge: [],
      //充值趋势X轴
      xRecharge: [],

      topData: {}, //上方数据

      searchTime: null, //查询时间
      selectData: null, //日期选择 0-今日  1-昨天  2-近7天 3-近30天

      //ios充值会员趋势
      iosVipRecharge: [],
      //paypal充值会员趋势
      paypayRecharge: [],
      //shopify充值会员趋势
      shopifyRecharge: [],
      //充值会员趋势X轴
      vipXRecharge: [],


      //充值方式统计
      payTypeTotal: [],


      //充值方式累计金额统计
      payTypeTotalMoney: [],


      //充值地域排行
      rechargeAddress: [],

      //订阅地域排行
      vipAddress: [],

    }
  },
  computed: {},
  created() {
    this.rechargeData()
    this.vipData()
    this.payTypeData()
    this.gerPayMoneyData()
    this.gerPayAddressData()
    this.gerVipAddressData()
    this.getTopData()

  },
  mounted() {

  },
  methods: {

    selectTopData(value) {
      let datas={
        selectData: value,
        searchTime: null
      }
      getTopData(datas).then(response => {
        this.topData = response.data
      })
    },
    getTimeSelect() {
      if (this.timeflag) {
        this.getTopData()
        this.timeflag = false
      } else {
        this.timeflag = true
      }
    },


    getTopData() {
      getTopData({selectData: null, searchTime: this.searchTime}).then(response => {
        this.topData = response.data
      })
    },


    // gerVipAddressData() {
    //   gerVipAddressData().then(res => {
    //     this.vipAddress = [];
    //     if (res.data) {
    //       this.vipAddress = res.data;//  订单量
    //       // this.iosRecharge = data.iosRecharge;
    //       // this.paypalRecharge = data.paypalRecharge;
    //       // this.xRecharge = data.xRecharge;
    //
    //       let myChart = echarts.init(document.getElementById('myChart5'));
    //       var option = {
    //         title: {
    //           text: '订阅地域排行',
    //           top: 10,
    //           left: 20
    //         },
    //
    //         legend: {
    //           left: 'center',
    //           top: 'bottom',
    //           data: [
    //             'rose1',
    //             'rose2',
    //             'rose3',
    //             'rose4',
    //             'rose5',
    //             'rose6',
    //             'rose7',
    //             'rose8'
    //           ]
    //         },
    //         toolbox: {
    //           show: true,
    //           feature: {
    //             mark: {show: true},
    //             dataView: {show: true, readOnly: false},
    //             restore: {show: true},
    //             saveAsImage: {show: true}
    //           }
    //         },
    //
    //         series: [
    //           {
    //             name: 'Access From',
    //             type: 'pie',
    //             radius: ['40%', '70%'],
    //             avoidLabelOverlap: false,
    //
    //             // itemStyle: {
    //             //   normal: {
    //             //     label: {
    //             //       show: true,
    //             //       formatter: '{b} : {c}'
    //             //     }
    //             //   },
    //             //   labelLine: {show: false}
    //             // },
    //             label: {
    //               show: false,
    //               position: 'center'
    //             },
    //             itemStyle: {
    //               normal: {
    //                 label: {
    //                   show: true,
    //                   formatter: '{b} : {c}'
    //                 }
    //               },
    //               labelLine: {show: true}
    //             },
    //             emphasis: {
    //               label: {
    //                 show: true
    //               }
    //             },
    //             data: this.vipAddress
    //           }
    //         ]
    //       };
    //       // 绘制图表
    //       option && myChart.setOption(option);
    //     }
    //   });
    // },


    gerVipAddressData() {
      gerVipAddressData().then(res => {
        this.vipAddress = [];
        if (res.data) {
          this.vipAddress = res.data;//  每日总量
          var chartDom = document.getElementById('myChart5');
          var myChart = echarts.init(chartDom);
          var option;
          option = {
            title: {
              text: '订阅地域排行',
              top: 5,
              left: 20
            },
            tooltip: {
              trigger: 'item'
            },
            legend: {
              top: '5%',
              left: 'center',
              show: false
            },
            series: [
              {
                name: '数据值',
                type: 'pie',
                radius: ['40%', '70%'],
                center: ['50%', '60%'],
                avoidLabelOverlap: false,
                padAngle: 5,
                itemStyle: {
                  borderRadius: 10
                },
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 20,
                    fontWeight: 'bold'
                  }
                },
                labelLine: {
                  show: false
                },
                data: this.vipAddress
              }
            ]
          };

          option && myChart.setOption(option);
        }
      });
    },


    // gerPayAddressData() {
    //   gerPayAddressData().then(res => {
    //     this.rechargeAddress = [];
    //     if (res.data) {
    //       this.rechargeAddress = res.data;//  订单量
    //       // this.iosRecharge = data.iosRecharge;
    //       // this.paypalRecharge = data.paypalRecharge;
    //       // this.xRecharge = data.xRecharge;
    //
    //       let myChart = echarts.init(document.getElementById('myChart4'));
    //       var option = {
    //         title: {
    //           text: '充值区域排行',
    //           top: 10,
    //           left: 20
    //         },
    //
    //         legend: {
    //           left: 'center',
    //           top: 'bottom',
    //           data: [
    //             'rose1',
    //             'rose2',
    //             'rose3',
    //             'rose4',
    //             'rose5',
    //             'rose6',
    //             'rose7',
    //             'rose8'
    //           ]
    //         },
    //         toolbox: {
    //           show: true,
    //           feature: {
    //             mark: {show: true},
    //             dataView: {show: true, readOnly: false},
    //             restore: {show: true},
    //             saveAsImage: {show: true}
    //           }
    //         },
    //         series: [
    //           {
    //             name: 'Access From',
    //             type: 'pie',
    //             radius: ['40%', '70%'],
    //             avoidLabelOverlap: false,
    //             // itemStyle: {
    //             //   normal: {
    //             //     label: {
    //             //       show: true,
    //             //       formatter: '{b} : {c}'
    //             //     }
    //             //   },
    //             //   labelLine: {show: true}
    //             // },
    //             itemStyle: {
    //               normal: {
    //                 label: {
    //                   show: true,
    //                   formatter: '{b} : {c}'
    //                 }
    //               },
    //               labelLine: {show: true}
    //             },
    //             emphasis: {
    //               label: {
    //                 show: true
    //               }
    //             },
    //             data: this.rechargeAddress
    //           }
    //         ]
    //       };
    //       // 绘制图表
    //       option && myChart.setOption(option);
    //     }
    //   });
    // },

    gerPayAddressData() {
      gerPayAddressData().then(res => {
        this.rechargeAddress = [];
        if (res.data) {
          this.rechargeAddress = res.data;//  每日总量
          var chartDom = document.getElementById('myChart4');
          var myChart = echarts.init(chartDom);
          var option;
          option = {
            title: {
              text: '充值区域排行',
              top: 5,
              left: 15
            },
            tooltip: {
              trigger: 'item'
            },
            legend: {
              top: '5%',
              left: 'center',
              show: false
            },
            series: [
              {
                name: '数据值',
                type: 'pie',
                radius: ['40%', '70%'],
                center: ['50%', '60%'],
                avoidLabelOverlap: false,
                padAngle: 5,
                itemStyle: {
                  borderRadius: 10
                },
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 20,
                    fontWeight: 'bold'
                  }
                },
                labelLine: {
                  show: false
                },
                data: this.rechargeAddress
              }
            ]
          };

          option && myChart.setOption(option);
        }
      });
    },


    // gerPayMoneyData() {
    //   gerPayMoneyData().then(res => {
    //     this.payTypeTotalMoney = [];
    //     if (res.data) {
    //       this.payTypeTotalMoney = res.data;//  订单量
    //       // this.iosRecharge = data.iosRecharge;
    //       // this.paypalRecharge = data.paypalRecharge;
    //       // this.xRecharge = data.xRecharge;
    //
    //       let myChart = echarts.init(document.getElementById('myChart3'));
    //       var option = {
    //         title: {
    //           text: '充值方式累计金额统计',
    //           top: 10,
    //           left: 20
    //         },
    //
    //         legend: {
    //           left: 'center',
    //           top: 'bottom',
    //           data: [
    //             'rose1',
    //             'rose2',
    //             'rose3',
    //             'rose4',
    //             'rose5',
    //             'rose6',
    //             'rose7',
    //             'rose8'
    //           ]
    //         },
    //         toolbox: {
    //           show: true,
    //           feature: {
    //             mark: {show: true},
    //             dataView: {show: true, readOnly: false},
    //             restore: {show: true},
    //             saveAsImage: {show: true}
    //           }
    //         },
    //         series: [
    //           {
    //             name: '数据值',
    //             type: 'pie',
    //             // radius: [40, 400],
    //             center: ['50%', '50%'],
    //             avoidLabelOverlap: false,
    //             roseType: 'area',
    //             // itemStyle: {
    //             //   borderRadius: 5
    //             // },
    //             itemStyle: {
    //               normal: {
    //                 label: {
    //                   show: true,
    //                   formatter: '{b} : {c}'
    //                 }
    //               },
    //               labelLine: {show: true}
    //             },
    //             emphasis: {
    //               label: {
    //                 show: true
    //               }
    //             },
    //             data: this.payTypeTotalMoney
    //           }
    //         ]
    //       };
    //       // 绘制图表
    //       option && myChart.setOption(option);
    //     }
    //   });
    // },

    gerPayMoneyData() {
      gerPayMoneyData().then(res => {
        this.payTypeTotalMoney = [];
        if (res.data) {
          this.payTypeTotalMoney = res.data;//  订单量
          // this.iosRecharge = data.iosRecharge;
          // this.paypalRecharge = data.paypalRecharge;
          // this.xRecharge = data.xRecharge;

          let myChart = echarts.init(document.getElementById('myChart3'));
          var option = {
            title: {
              text: '充值方式累计金额',
              top: 0,
              left: 20
            },
            tooltip: {
              trigger: 'item'
            },
            legend: {
              top: '10%',
              left: 'center',
              show: false
            },
            toolbox: {
              show: true,
              feature: {
                mark: {show: true},
                dataView: {show: true, readOnly: false},
                restore: {show: true},
                saveAsImage: {show: true}
              }
            },
            series: [
              {
                // name: '统计值值',
                type: 'pie',
                // radius: [40, 400],
                center: ['50%', '60%'],
                avoidLabelOverlap: false,
                roseType: 'area',
                // itemStyle: {
                //   borderRadius: 5
                // },
                itemStyle: {
                  normal: {
                    label: {
                      show: false,
                      position: 'outside',
                      formatter: '{b} : {c}'
                    }
                  },
                  labelLine: {show: true}
                },
                emphasis: {
                  label: {
                    show: false
                  }
                },
                data: this.payTypeTotalMoney
              }
            ]
          };
          // 绘制图表
          option && myChart.setOption(option);
        }
      });
    },


    payTypeData() {
      payTypeData().then(res => {
        this.payTypeTotal = [];
        if (res.data) {
          this.payTypeTotal = res.data;//  订单量
          // this.iosRecharge = data.iosRecharge;
          // this.paypalRecharge = data.paypalRecharge;
          // this.xRecharge = data.xRecharge;

          let myChart = echarts.init(document.getElementById('myChart2'));
          var option = {
            title: {
              text: '充值方式统计',
              top: 5,
              left: 10
            },
            tooltip: {
              trigger: 'item'
            },
            legend: {
              top: '10%',
              left: 'center',
              show: false
            },
            toolbox: {
              show: true,
              feature: {
                mark: {show: true},
                dataView: {show: true, readOnly: false},
                restore: {show: true},
                saveAsImage: {show: true}
              }
            },
            series: [
              {
                // name: '统计值值',
                type: 'pie',
                // radius: [40, 400],
                center: ['50%', '60%'],
                avoidLabelOverlap: false,
                roseType: 'area',
                // itemStyle: {
                //   borderRadius: 5
                // },
                itemStyle: {
                  normal: {
                    label: {
                      show: false,
                      position: 'outside',
                      formatter: '{b} : {c}'
                    }
                  },
                  labelLine: {show: true}
                },
                emphasis: {
                  label: {
                    show: false
                  }
                },
                data: this.payTypeTotal
              }
            ]
          };
          // 绘制图表
          option && myChart.setOption(option);
        }
      });
    },

    vipData() {
      vipData().then(res => {
        //ios充值会员趋势
        this.iosVipRecharge = [],
            //paypal充值会员趋势
            this.paypayRecharge = [],
            //shopify充值会员趋势
            this.shopifyRecharge = [],
            //充值会员趋势X轴
            this.vipXRecharge = []
        if (res.data) {

          //ios充值会员趋势
          this.iosVipRecharge = res.data.iosVipRecharge,
              //paypal充值会员趋势
              this.paypayRecharge = res.data.paypayRecharge,
              //shopify充值会员趋势
              this.shopifyRecharge = res.data.shopifyRecharge,
              //充值会员趋势X轴
              this.vipXRecharge = res.data.vipXRecharge

          let myChart = echarts.init(document.getElementById('myChart1'));
          var option = {
            title: {
              text: '订阅趋势',
              top: 10,
              left: 20
            },
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['ios充值会员趋势', 'paypal充值会员趋势', 'shopify充值会员趋势']
            },


            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              data: this.vipXRecharge
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                data: this.iosVipRecharge,
                type: 'line',
                smooth: true
              },
              {
                data: this.iosVipRecharge,
                type: 'line',
                smooth: true
              },
              {
                data: this.shopifyRecharge,
                type: 'line',
                smooth: true
              }
            ]
          };
          // 绘制图表
          option && myChart.setOption(option);
        }
      });
    },

    rechargeData() {
      rechargeData().then(res => {
        //ios充值趋势
        this.iosRecharge= [],
            //paypal充值趋势
        this.paypalRecharge= [],
            //充值趋势X轴
        this.xRecharge= []
        if (res.data) {
          //ios充值趋势
          this.iosRecharge= res.data.iosRecharge,
              //paypal充值趋势
              this.paypalRecharge= res.data.paypalRecharge,
              //充值趋势X轴
              this.xRecharge= res.data.xRecharge

          let myChart = echarts.init(document.getElementById('myChart'));
          var option = {
            title: {
              text: '充值趋势',
              top: 10,
              left: 20
            },
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['ios充值趋势', 'paypal充值趋势']
            },


            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              data: this.xRecharge
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                data: this.iosRecharge,
                type: 'line',
                smooth: true
              },
              {
                data: this.paypalRecharge,
                type: 'line',
                smooth: true
              }
            ]
          };
          // 绘制图表
          option && myChart.setOption(option);
        }
      });

    },


  },


}
</script>

<style lang="less" scoped>
@import "../../index.less";

blockquote {
  padding: 0 1em;
  color: #6a737d;
  border-left: 0.25em solid #dfe2e5;
}

.project-list {

  .card-title {
    font-size: 0;

    a {
      color: rgba(0, 0, 0, 0.85);
      margin-left: 12px;
      line-height: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;

      &:hover {
        color: #1890ff;
      }
    }
  }

  .card-description {
    color: rgba(0, 0, 0, 0.45);
    height: 66px;
    line-height: 22px;
    overflow: hidden;
  }

  .project-item {
    display: flex;
    margin-top: 8px;
    overflow: hidden;
    font-size: 12px;
    height: 20px;
    line-height: 20px;

    a {
      color: rgba(0, 0, 0, 0.45);
      display: inline-block;
      flex: 1 1 0;

      &:hover {
        color: #1890ff;
      }
    }

    .download {
      color: rgba(0, 0, 0, 0.25);
      flex: 0 0 auto;
      float: right;
    }
  }

  .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
    height: 44px;
    line-height: 22px;
    overflow: hidden;
  }
}

.item-group {
  padding: 20px 0 8px 24px;
  font-size: 0;

  a {
    color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    font-size: 14px;
    margin-bottom: 13px;
    width: 25%;
  }
}

.mobile {

  .project-list {

    .project-card-grid {
      width: 100%;
    }
  }

  .more-info {
    border: 0;
    padding-top: 16px;
    margin: 16px 0 16px;
  }

  .headerContent .title .welcome-text {
    display: none;
  }
}

</style>
